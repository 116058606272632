<template>
	<div>
		<v-navigation-drawer
			id="core-navigation-admin-drawer"
			v-model="adminDrawer"
			app
			cliped
			right
			temporary
			mobile-break-point="960"
		>
			<v-list two-line dense>
				<v-list-item>
					<v-img :src="companyData.logo" :aspect-ratio="1.5" contain></v-img>
				</v-list-item>

				<v-list-item class="text-center">
					<v-list-item-content>
						<v-list-item-title class="text-wrap pb-1">{{companyData.companyVisualName}}</v-list-item-title>
						<v-list-item-subtitle>{{companyData.branchOfficeVisualName}}</v-list-item-subtitle>
					</v-list-item-content>
				</v-list-item>
			</v-list>

			<v-divider></v-divider>

			<v-list subheader>
				<v-list-item>
					<v-list-item-content>
						<v-list-item-title>Miembro desde {{companyData.subscribedSince}}</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
				
				<v-divider></v-divider>

				<v-subheader>Información del plan</v-subheader>
				<v-list-item two-line>
					<v-list-item-content>
						<v-list-item-title>{{companyData.servicePlan}}</v-list-item-title>
						<v-list-item-subtitle>{{companyData.branchOfficeLimit}} sucursales, {{companyData.userLimit}} usuarios</v-list-item-subtitle>
						<v-list-item-subtitle>{{companyData.productLimit}} productos</v-list-item-subtitle>
					</v-list-item-content>
				</v-list-item>
				<v-list-item two-line>
					<v-list-item-content>
						<v-list-item-title>Próxima fecha de pago</v-list-item-title>
						<v-list-item-subtitle>{{companyData.nextPaymentDate}}</v-list-item-subtitle>
					</v-list-item-content>
				</v-list-item>
				<!-- <v-list-item @click="openDialog(4)">
					<v-list-item-content>
						<v-list-item-title class="white-space-normal">Información de los pagos</v-list-item-title>
					</v-list-item-content>
					<v-list-item-avatar>
						<v-icon>mdi-menu-right</v-icon>
					</v-list-item-avatar>
				</v-list-item> -->
				<!-- <v-list-item two-line @click="openDialog(5)">
					<v-list-item-content>
						<v-list-item-title class="white-space-normal">Canjear código promocional</v-list-item-title>
					</v-list-item-content>
					<v-list-item-avatar>
						<v-icon>mdi-menu-right</v-icon>
					</v-list-item-avatar>
				</v-list-item> -->
			</v-list>

			<v-divider></v-divider>

			<v-list subheader>
				<v-subheader>Configuración</v-subheader>
				<v-list-item v-for="item in items"
					:key="item.title"
					@click="openDialog(item.formType)"
				>
					<v-list-item-avatar>
						<v-icon	v-text="item.icon"></v-icon>
					</v-list-item-avatar>
					<v-list-item-content>
						<v-list-item-title>{{ item.title }}</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
			</v-list>

			<!-- <template v-slot:append>
				<div class="pa-2">
					<v-btn block color="primary" @click="{adminDrawer = !adminDrawer; $router.push({ name: 'Precios' }); }">
						Ver planes
					</v-btn>
				</div>
			</template> -->
		</v-navigation-drawer>

		<v-dialog :fullscreen="$vuetify.breakpoint.mobile || $vuetify.breakpoint.smAndDown" v-model="showDialog" persistent scrollable :max-width="selectedFormType.maxWidth">
			<base-form-simple v-if="selectedFormType.value == 1" ref="CompanyForm"
				formTitle="Información de la empresa"
				:formLoading="formLoading"
				@cancelButton="closeCompanyData"
				@saveButton="saveCompanyData"
			>
				<template v-slot:formContent>
					<v-container class="no-padding" fluid>
						<v-row>
							<v-col cols="12" sm="6" md="6">
								<base-text-field v-model="editedCompany.visualName" label="Nombre Empresa/Negocio" counter maxlength="100" 
								:rules="[commonValRules.required, validationRules.companyData.visualNameRules.counter]" required></base-text-field>
							</v-col>
							<v-col cols="12" sm="6" md="6">
								<base-text-field v-model="editedCompany.businessSector" label="Rubro" disabled></base-text-field>
							</v-col>
							<v-col cols="12" sm="6" md="6">
								<base-text-field v-model="editedCompany.country" label="País" disabled></base-text-field>
							</v-col>
							<v-col cols="12" sm="6" md="6">
								<base-text-field v-model="editedCompany.servicePlan" label="Plan de Servicio" disabled></base-text-field>
							</v-col>
						</v-row>
					</v-container>
				</template>
			</base-form-simple>

			<base-form-simple v-else-if="selectedFormType.value == 2"
				formTitle="Cambiar logo de la empresa"
				:formLoading="formLoading"
				hideLabelRequiredFields
				:hideSaveButton="!hasNewLogo"
				@cancelButton="closeCompanyLogo"
				@saveButton="saveCompanyLogo"
			>
				<template v-slot:formContent>
					<v-container class="no-padding" fluid>
						<v-row>
							<v-col cols="12" sm="12" md="12">
								<base-upload-file :title="'Elija su logo'" name="logoChange" v-bind:image="newCompanyLogo" v-on:onChange="onFileChange" :accept="allowedFiles">
								</base-upload-file>
							</v-col>
						</v-row>
					</v-container>
				</template>
			</base-form-simple>

			<base-form-simple v-else-if="selectedFormType.value == 3" ref="ProductForm"
				formTitle="Configuraciones de los productos"
				:formLoading="formLoading"
				hideLabelRequiredFields
				@cancelButton="closeProductData"
				@saveButton="saveProductData"
			>
				<template v-slot:formContent>
					<v-container class="no-padding pt-5" fluid>
						<v-row no-gutters>
							<v-col cols="12" sm="12" md="4" align="center" class="mb-5 mb-md-0 px-md-2">
								<v-card min-height="280">
									<v-list-item three-line dense>
										<v-list-item-content>
											<v-list-item-title class="title font-weight-regular">Stock bajo</v-list-item-title>
											<v-list-item-subtitle class="white-space-normal">Los productos cuyo stock sean menores al indicador, serán considerados con stock bajo.</v-list-item-subtitle>
										</v-list-item-content>
									</v-list-item>
									
									<v-card-text class="pt-0">
										<v-row
											class="mb-4"
										>
											<v-col class="text-center">
												<span
													class="display-2 font-weight-light"
													v-text="editedProductConfig.low"
												></span>
												<br>
												<span class="subheading font-weight-light"> Indicador</span>
											</v-col>
											<v-col class="text-right">
												<v-avatar
													color="red"
												>
													<v-icon dark size="25">mdi-less-than</v-icon>
												</v-avatar>
											</v-col>
										</v-row>

										<v-slider
											v-model="editedProductConfig.low"
											color="red"
											track-color="grey"
											always-dirty
											min="1"
											:max="maxLowStock"
											:rules="[validationRules.productData.lowRules.counter, validationRules.productData.lowRules.number, validationRules.productData.lowRules.isValid]"
										>
											<template v-if="editedProductConfig.low > 0" v-slot:prepend>
												<v-icon
													color="red"
													@click="editedProductConfig.low--"
												>
													mdi-minus
												</v-icon>
											</template>

											<template v-if="editedProductConfig.low < maxLowStock" v-slot:append>
												<v-icon
													color="red"
													@click="editedProductConfig.low++"
												>
													mdi-plus
												</v-icon>
											</template>
										</v-slider>
									</v-card-text>
								</v-card>
							</v-col>
							<v-col cols="12" sm="12" md="4" align="center" class="mb-5 mb-md-0 px-md-2">
								<v-card min-height="280">
									<v-list-item three-line dense>
										<v-list-item-content>
											<v-list-item-title class="title font-weight-regular">Stock regular</v-list-item-title>
											<v-list-item-subtitle class="white-space-normal">Los productos cuyo stock se encuentren en el intervalo del indicador, serán considerados con stock regular.</v-list-item-subtitle>
										</v-list-item-content>
									</v-list-item>
									
									<v-card-text class="pt-0">
										<v-row
											class="mb-4"
										>
											<v-col cols="8" class="text-center">
												<span
													class="display-2 font-weight-light"
													v-text="`${editedProductConfig.low} - ${editedProductConfig.high}`"
												></span>
												<br>
												<span class="subheading font-weight-light"> Indicador</span>
											</v-col>
											<v-col cols="4" class="text-right">
												<v-avatar
													color="orange"
												>
													<v-icon dark size="20">mdi-greater-than-or-equal</v-icon>
													<v-icon dark size="20">mdi-less-than-or-equal</v-icon>
												</v-avatar>
											</v-col>
										</v-row>
									</v-card-text>
								</v-card>
							</v-col>
							<v-col cols="12" sm="12" md="4" align="center" class="px-md-2">
								<v-card min-height="280">
									<v-list-item three-line dense>
										<v-list-item-content>
											<v-list-item-title class="title font-weight-regular">Stock alto</v-list-item-title>
											<v-list-item-subtitle class="white-space-normal">Los productos cuyo stock sean mayores al indicador, serán considerados con excelente stock.</v-list-item-subtitle>
										</v-list-item-content>
									</v-list-item>
									
									<v-card-text class="pt-0">
										<v-row
											class="mb-4"
										>
											<v-col class="text-center">
												<span
													class="display-2 font-weight-light"
													v-text="editedProductConfig.high"
												></span>
												<br>
												<span class="subheading font-weight-light"> Indicador</span>
											</v-col>
											<v-col class="text-right">
												<v-avatar
													color="green"
												>
													<v-icon dark size="25">mdi-greater-than</v-icon>
												</v-avatar>
											</v-col>
										</v-row>

										<v-slider
											v-model="editedProductConfig.high"
											color="green"
											track-color="grey"
											always-dirty
											:min="minHighStock"
											max="100"
											:rules="[validationRules.productData.highRules.counter, validationRules.productData.highRules.number, validationRules.productData.highRules.isValid]"
										>
											<template v-if="editedProductConfig.high > minHighStock" v-slot:prepend>
												<v-icon
													color="green"
													@click="editedProductConfig.high--"
												>
													mdi-minus
												</v-icon>
											</template>

											<template v-if="editedProductConfig.high < 100" v-slot:append>
												<v-icon
													color="green"
													@click="editedProductConfig.high++"
												>
													mdi-plus
												</v-icon>
											</template>
										</v-slider>
									</v-card-text>
								</v-card>
							</v-col>							
						</v-row>
					</v-container>
				</template>
			</base-form-simple>

			<base-form-simple v-else-if="selectedFormType.value == 4"
				formTitle="Información de los pagos"
				:formLoading="formLoading"
				hideLabelRequiredFields
				hideSaveButton
				cancelButtonText="Cerrar"
				@cancelButton="closePaymentInfo"
			>
				<template v-slot:formContent>
					<v-container class="no-padding pt-5" fluid>
						<v-card class="mb-5">
							<v-subheader class="subtitle-1">Tu suscripción</v-subheader>
							<v-row no-gutters>
								<v-col cols="12" sm="6">
									<v-list-item two-line>
										<v-list-item-content class="py-0">
											<v-list-item-title class="subtitle-1 font-weight-regular">Plan: {{companyData.servicePlan}}</v-list-item-title>
											<v-list-item-subtitle>Miembro desde {{companyData.subscribedSince}}</v-list-item-subtitle>
										</v-list-item-content>
									</v-list-item>	
								</v-col>
								<v-col cols="12" sm="6">
									<v-list-item two-line>
										<v-list-item-content class="py-0">
											<v-list-item-title class="subtitle-1 font-weight-regular">Tu próxima fecha de pago</v-list-item-title>
											<v-list-item-subtitle>{{companyData.nextPaymentDate}}</v-list-item-subtitle>
										</v-list-item-content>
									</v-list-item>	
								</v-col>
								<v-col cols="12" sm="4">
									<v-list-item>
										<v-list-item-avatar>
											<v-icon color="primary">mdi-home-city</v-icon>
										</v-list-item-avatar>
										<v-list-item-title v-text="`Sucursales ${companyData.branchOfficeLimit}`"></v-list-item-title>
									</v-list-item>
								</v-col>
								<v-col cols="12" sm="4">
									<v-list-item>
										<v-list-item-avatar>
											<v-icon color="secondary">mdi-account-group</v-icon>
										</v-list-item-avatar>
										<v-list-item-title v-text="`Usuarios ${companyData.userLimit}`"></v-list-item-title>
									</v-list-item>
								</v-col>
								<v-col cols="12" sm="4">
									<v-list-item>
										<v-list-item-avatar>
											<v-icon color="info">mdi-cart</v-icon>
										</v-list-item-avatar>
										<v-list-item-title v-text="`Productos ${companyData.productLimit}`"></v-list-item-title>
									</v-list-item>
								</v-col>
								<v-col cols="12">
									<v-expansion-panels flat>
										<v-expansion-panel>
											<v-expansion-panel-header class="px-4" expand-icon="mdi-menu-down">
												Cargos adicionales
											</v-expansion-panel-header>
											<v-expansion-panel-content>
												<v-list v-if="$vuetify.breakpoint.smAndDown" three-line class="py-0">
													<template v-for="(item, index) in paymentInfo.additionalServices">
														<v-list-item class="px-0"
															:key="item.id"
														>
															<v-list-item-content>
																<v-list-item-title class="subtitle-2 secondary--text">{{ item.since }}</v-list-item-title>
																<v-list-item-subtitle>{{ item.description }}</v-list-item-subtitle>
																<v-list-item-subtitle>Cantidad: {{ item.quantity }}</v-list-item-subtitle>
															</v-list-item-content>

															<v-list-item-action>
																<v-list-item-action-text class="subtitle-2" v-text="item.totalAmount"></v-list-item-action-text>
															</v-list-item-action>
														</v-list-item>
														<v-divider
															v-if="index < paymentInfo.additionalServices.length - 1"
															:key="index"
														></v-divider>
													</template>
												</v-list>
												<base-data-table v-else
													:headers="additionalServiceHeaders" 
													:items="paymentInfo.additionalServices"
													sortBy="since"
													hideHeader
													hideFooter
													elevation="0"
													showBorder
												>
												</base-data-table>
											</v-expansion-panel-content>
										</v-expansion-panel>
									</v-expansion-panels>
								</v-col>
							</v-row>
						</v-card>
						
						<v-list v-if="$vuetify.breakpoint.smAndDown" three-line class="py-0">
							<template v-for="(item, index) in paymentInfo.payments">
								<v-list-item class="px-0"
									:key="item.id"
								>
									<v-list-item-content>
										<v-list-item-title class="subtitle-2 primary--text">{{ item.paymentDate }}</v-list-item-title>
										<v-list-item-subtitle>{{ item.description }}</v-list-item-subtitle>
										<v-list-item-subtitle>{{ item.intervalDates }}</v-list-item-subtitle>
										<v-list-item-subtitle>{{ item.paymentType }}</v-list-item-subtitle>
									</v-list-item-content>

									<v-list-item-action>
										<v-list-item-action-text class="subtitle-2" v-text="item.totalAmount"></v-list-item-action-text>
									</v-list-item-action>
								</v-list-item>
								<v-divider
									v-if="index < paymentInfo.payments.length - 1"
									:key="index"
								></v-divider>
							</template>
						</v-list>
						<base-data-table v-else
							:headers="paymentHeaders" 
							:items="paymentInfo.payments"
							sortBy="paymentDate"
							desc
							hideHeader
							hideFooter
							rows="12"
							elevation="0"
							showBorder
						>
						</base-data-table>
						<small>NOTA: Solo se muestra el último año del historial de pagos.</small>
					</v-container>
				</template>
			</base-form-simple>
		</v-dialog>
	</div>
</template>

<script>
import { mapState } from "vuex";
// Mixins
import MainExternalData from '@/mixins/main-external-data'

export default {
	name: "GavcomCoreAdminDrawer",
	mixins: [MainExternalData],

	data() {
		return {
			items: [
				{ title: 'Empresa', icon: 'mdi-domain', formType: 1 },
				{ title: 'Logo', icon: 'mdi-image-edit-outline', formType: 2 },
				{ title: 'Productos', icon: 'mdi-tune-variant', formType: 3 },
			],
			showDialog: false,
			formTypes: [
				{ value: 1, title:'Información de la empresa', maxWidth:'800px', fetch:'getCompanyData' },
				{ value: 2, title:'Cambiar logo de la empresa', maxWidth:'400px' },
				{ value: 3, title:'Configuraciones de los productos', maxWidth:'1000px', fetch:'getProductData' },
				{ value: 4, title:'Información de los pagos', maxWidth:'900px', fetch:'getPaymentInfo' },
				{ value: 5, title:'Canjear código promocional', maxWidth:'0' },
			],
			selectedFormType: {},
			formLoading: false,
			//1-COMPANY DATA
			editedCompany: {
        visualName: '',
        businessSector: '',
				servicePlan: '',
				country: '',
      },
      defaultCompany: {
        visualName: '',
        businessSector: '',
				servicePlan: '',
				country: '',
			},
			//2-COMPANY LOGO
			allowedFiles:['.jpeg', '.jpg', '.png'],
			newCompanyLogo: null,
			//3-PRODUCT CONFIG
			editedProductConfig: {
				low: 0,
				high: 0
			},
			defaultProductConfig: {
				low: 0,
				high: 0
			},
			//4-PAYMENT INFO
			paymentHeaders: [
				{ text: "Fecha", value: "paymentDate", sortable: false },
				{ text: "Descripción", value: "description", sortable: false },
				{ text: "Periodo del servicio", value: "intervalDates", sortable: false },
				{ text: "Forma de pago", value: "paymentType", sortable: false },
				{ text: "Total", value: "totalAmount", sortable: false },
			],
			additionalServiceHeaders: [
				{ text: "Desde", value: "since", sortable: false },
				{ text: "Descripción", value: "description", sortable: false },
				{ text: "Cantidad", value: "quantity", sortable: false },
				{ text: "Total", value: "totalAmount", sortable: false },
			],
			paymentInfo: {
				payments: [],
				additionalServices: []
			},
			defaultPaymentInfo: {
				payments: [],
				additionalServices: []
			},

			validationRules: {
				companyData: {
					visualNameRules: {
						counter: value => (value.length >= 5 && value.length <= 100) || 'No debe tener más de 100 caracteres, ni menos de 5 caracteres.',
					},
				},
				productData: {
					lowRules: {
						counter: value => (value != null && value.toString().length <= 4) || 'No debe tener más de 4 dígitos.',
          	number: value => (value > 0) || 'Debe ser mayor a 0.',
						isValid: value => (value <= this.maxLowStock) || 'Valor fuera de rango.',
					},
					highRules: {
						counter: value => (value != null && value.toString().length <= 4) || 'No debe tener más de 4 dígitos.',
          	number: value => (value > 0 && value <= 100) || 'Debe ser entre 1 y 100.',
						isValid: value => (value >= this.minHighStock) || 'Valor fuera de rango.',
					}
				}
			}
		}
	},

	computed: {
		...mapState(["companyData"]),
		adminDrawer: {
      get() {
        return this.$store.state.adminDrawer;
      },
      set(val) {
        this.$store.commit("SET_ADMIN_DRAWER", val);
      }
		},
		
		hasNewLogo() {
			if (this.newCompanyLogo) {
				return true
			}
			return false
		},

		maxLowStock() {
			const max = this.editedProductConfig.high - 5;
			return max;
		},

		minHighStock() {
			const min = this.editedProductConfig.low  + 5;
			return min;
		}
	},

	methods: {
		async openDialog(formType) {
			if (formType <= 0) {
				return;
			}
			let me = this;
			me.selectedFormType = me.formTypes.find(x => x.value == formType);
			if (me.selectedFormType == undefined || me.selectedFormType == null) {
				return;
			}
			if(!!me.selectedFormType.fetch) {
				if (!await me[me.selectedFormType.fetch]()) {
					return;
				}
			}
			// this.adminDrawer = !this.adminDrawer
      me.showDialog = true;
		},

		closeDialog() {
			let me = this;
			me.selectedFormType = {};
      me.showDialog = false;
    },

		//1-COMPANY DATA
		async getCompanyData() {
			let me = this;
			let result = false;
			try {
        me.SHOW_LOADING()
				let request={
					'companyId': me.baseRequest.companyId,
					'userId': me.baseRequest.userId,
				};
				await me.getObjectResponse('api/Company/GetOwnInfo', request).then(data => {
					if (data === undefined) {
						return result;
					}
					me.editedCompany = Object.assign({}, data.company);
					result = true;
				}).catch(function(error){
					me.$swal(me.swalConfig.errorTitle, error.message, 'error');
				});
			} catch (error) {
        me.catchError(error)
      } finally {
        me.HIDE_LOADING()
      }
			return result;
		},
		
		async saveCompanyData(){
			let me = this;
			try {
				me.SHOW_LOADING()
				me.formLoading = true;
				let request={
					'companyId': me.baseRequest.companyId,
					'userId': me.baseRequest.userId,
					'visualName': me.editedCompany.visualName,
				};
				await me.getBaseResponse('api/Company/UpdateOwnData', request).then(data => {
					if (data) {
						me.$store.commit("SET_COMPANY_VISUALNAME", {companyVisualName: me.editedCompany.visualName});
						me.closeCompanyData();
						me.showSuccessModal();
					}
				}).catch(function(error){
					me.$swal(me.swalConfig.errorTitle, error.message, 'error');
				});
			} catch (error) {
        me.catchError(error)
      } finally {
				me.HIDE_LOADING()
				me.formLoading = false;
      }
		},

    closeCompanyData() {
			this.$refs.CompanyForm.reset();
			this.editedCompany = Object.assign({}, this.defaultCompany);
			this.closeDialog();
		},
		
		//2-COMPANY LOGO
		onFileChange(name, file){
			this.newCompanyLogo = null;
			if (file){
				this.newCompanyLogo = file.base64;
			}
		},
		
		async saveCompanyLogo(){
			let me=this;
			try {
				me.SHOW_LOADING()
				me.formLoading = true;
				let request={
					'companyId': me.baseRequest.companyId,
					'userId': me.baseRequest.userId,
					'logo': me.newCompanyLogo,
				};
				await me.getBaseResponse('api/Company/ChangeLogo', request).then(data => {
					if (data) {
						me.$store.commit("SET_COMPANY_LOGO", me.newCompanyLogo);
						me.closeCompanyLogo();
						me.showSuccessModal();
					}
				}).catch(function(error){
					me.$swal(me.swalConfig.errorTitle, error.message, 'error');
				});
			} catch (error) {
        me.catchError(error)
      } finally {
				me.HIDE_LOADING()
				me.formLoading = false;
      }
		},

    closeCompanyLogo() {
      this.newCompanyLogo = null;
      this.closeDialog();
    },

		//3-PRODUCT CONFIG
		async getProductData() {
			let me = this;
			let result = false;
			try {
        me.SHOW_LOADING()
				let request={
					'companyId': me.baseRequest.companyId,
					'userId': me.baseRequest.userId,
				};
				await me.getObjectResponse('api/Company/GetStockFlags', request).then(data => {
					if (data === undefined) {
						return result;
					}
					me.editedProductConfig = Object.assign({}, data);
					result = true;
				}).catch(function(error){
					me.$swal(me.swalConfig.errorTitle, error.message, 'error');
				});
			} catch (error) {
        me.catchError(error)
      } finally {
        me.HIDE_LOADING()
      }
			return result;
		},

		async saveProductData(){
			let me=this;
			try {
				me.SHOW_LOADING()
				me.formLoading = true;
				let request={
					'companyId': me.baseRequest.companyId,
					'userId': me.baseRequest.userId,
					'low': me.editedProductConfig.low,
					'high': me.editedProductConfig.high
				};
				await me.getBaseResponse('api/Company/UpdateStockFlags', request).then(data => {
					if (data) {
						me.closeProductData();
						me.showSuccessModal();
					}
				}).catch(function(error){
					me.$swal(me.swalConfig.errorTitle, error.message, 'error');
				});
			} catch (error) {
        me.catchError(error)
      } finally {
				me.HIDE_LOADING()
				me.formLoading = false;
      }
		},

		closeProductData() {
      this.$refs.ProductForm.reset();
			this.editedProductConfig = Object.assign({}, this.defaultProductConfig);
			this.closeDialog();
    },

		//4-PAYMENT INFO
		async getPaymentInfo() {
			let me = this;
			let result = false;
			try {
        me.SHOW_LOADING()
				let request={
					'companyId': me.baseRequest.companyId,
					'userId': me.baseRequest.userId,
				};
				await me.getObjectResponse('api/CompanyService/GetPaymentInfo', request).then(data => {
					if (data === undefined) {
						return result;
					}
					me.paymentInfo = Object.assign({}, data);
					result = true;
				}).catch(function(error){
					me.$swal(me.swalConfig.errorTitle, error.message, 'error');
				});
			} catch (error) {
        me.catchError(error)
      } finally {
        me.HIDE_LOADING()
      }
			return result;
		},

		closePaymentInfo() {
			this.paymentInfo = Object.assign({}, this.defaultPaymentInfo);
			this.closeDialog();
    },
	},
}
</script>
<style scoped>
  .white-space-normal{
    white-space: normal;
		display: flex;
  }
</style>